@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background-color: #f8f9ff !important;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

th {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 500 !important;
}

/* Works on Firefox */
* {
  scrollbar-width: 4px;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px !important;
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 6px; /* width of vertical scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #2ad67e;
  border-radius: 20px;
}
.custom-date-input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  font-size: 14px;
  font-weight: 400;
}

.custom-date-input:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}